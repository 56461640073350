import axios from "axios";
import {authConfig} from "./person";

export async function editDescription(token, user_id, data){
    return new Promise((resolve, reject) => {
        axios.put(
            process.env.REACT_APP_API_URL_PREFIX + `/person/description/${user_id}`, data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

import '../../../css/App.css'
import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalDialog from "../../utils/ModalDialog";
import DropdownGroup from "../../utils/DropdownGroup";
import moment from "moment";
import axios from "axios";
import {CreateTodoModal} from "../../contents/modalContents/CreateTodoModal";
import {useStoreContext} from "../../hooks/useStoreContext";

function CreateToDo({todoTypes, date}) {
    const {store} = useStoreContext();
    const [allTypes, setAllTypes] = useState([]);

    useEffect(() => {
        if(todoTypes) {
            setAllTypes(todoTypes)
        }
    }, [todoTypes])

    return (
        <div className="inline">
            <button className="btn btn-success ml-3" onClick={()=>document.getElementById('create_todo_modal').showModal()}>
                <FontAwesomeIcon icon={"plus"}/>
            </button>{store.todos.length === 0 && !store.loading && <b className="text-lg ml-4">← Füge Todos hinzu</b>}
            <CreateTodoModal selectedDate={date} allTypes={allTypes} />
        </div>
    );
}
export default CreateToDo;

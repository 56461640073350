import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';
import InputFileUpload from "./InputFileUpload";
import {useStoreContext} from "../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../StoreProvider";
import {uploadProfileVisionboardPic} from "../../api/person";

function FileInputGroup({children, classes, userId, img}){
    const {store, dispatch} = useStoreContext();
    const [image, setImage] = useState();
    const [imagePreview, setImagePreview] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [saved, setSaved] = useState(true)

    const handleImageSelection = (e) => {
        const file = (e.target.files[0])
        setImagePreview(URL.createObjectURL(file));
        setImage(file)
        setIsSelected(true)
        setSaved(false)
    }

    useEffect(() => {
        if(img && img[0].images.length !== 0){
            setImage(img[0]?.images[0])
            setImagePreview(img[0]?.images[0])
            setIsSelected(true)
            setSaved(true)
        }
    }, [img])

    const handleSave = () => {
        const formData = new FormData;
        formData.append('images[]', image)
        formData.append('id', userId)
        formData.append('desc_type', 'profile');
        formData.append('desc_sub_type', 'visionboard');

        const performUpload = async () => {
            await uploadProfileVisionboardPic(store.token, formData)
                .then(res => {
                    setSaved(true)
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "images", value: {...store.images,
                                profile: store.images.profile.map(p => {
                                    if(p.desc_sub_type === "visionboard"){return {...p, images: res}}else{return p}
                                })}}})
                });
        }
        performUpload().finally();
    }

    const handleDelete = () => {
        setImage()
        setImagePreview()
        setIsSelected(false)
        setSaved(false)
        handleSave()
    }

    return (
        <div className={classes}>
            {isSelected ?
                <div className="relative w-full h-full group">
                    <img src={imagePreview} alt={`${imagePreview}`} className="max-w-2xl w-full h-full object-cover" />
                    {/*Todo: Bild wird aktuell noch abgeschnitten, aber wenn object-contain, wären die Buttons nicht im Bild*/}
                    <div className="absolute bottom-0 left-0 md:right-0 px-3 md:px-0 py-3">
                        {!saved && <button className="btn btn-primary" onClick={() => handleSave()}>Speichern</button>}
                    </div>
                    <div className="absolute bottom-0 right-0 md:right-10 p-3 block group-hover:block lg:hidden">
                        <div className="tooltip" data-tip="Bearbeiten">
                            <label htmlFor="editImage" className="btn btn-accent mr-3"><FontAwesomeIcon icon={"edit"} /></label>
                            <InputFileUpload update={(e) => handleImageSelection(e)} id={"editImage"} classes={"hidden"} />
                        </div>
                        <div className="tooltip" data-tip="Löschen">
                            <button className="btn btn-error" onClick={handleDelete}><FontAwesomeIcon icon={"trash-can"} /></button>
                        </div>
                    </div>
                </div>
            :
                <div className={"border-dashed border-2 grid grid-rows-3 min-h-full "}>
                    {children}
                    <div className="px-3">
                        <InputFileUpload update={(e) => handleImageSelection(e)} />
                    </div>
                <div />
        </div>
            }
        </div>
    )
}

export default FileInputGroup


import TestVideo from "../../../images/TestVideo.mp4";
import React from "react";
import ProfileNavigation from "../ProfileNavigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ConfigureJourneyStepModal} from "../../contents/modalContents/ConfigureJourneyStepModal";
import {SortJourneyStepsModal} from "../../contents/modalContents/SortJourneyStepsModal";

export function JourneySection({ index, item }){
    return (
        <div id={`journeySection${item.id}`} className="hero min-h-screen snap-start " style={{backgroundImage: `url(${item.isImg && item.background})`}}>
            {!item.isImg && <video autoPlay loop muted id='video' className="w-100">
                <source src={item.background} type={`video/mp4`}/>
            </video>}
            <div className="hero-overlay bg-opacity-70">
                <div className="flex sm:justify-end mx-auto container mt-20 gap-3">
                    <button className="btn btn-success top-20 sm:w-auto w-1/3 sm:mx-0 mx-auto "
                            onClick={()=>document.getElementById(`sortJourneySteps`).showModal()}>
                        <FontAwesomeIcon icon={"list-ol"} className="text-lg"/>
                    </button>
                    <SortJourneyStepsModal />
                    <button className="btn btn-success top-20 sm:w-auto w-1/3 sm:mx-0 mx-auto "
                            onClick={()=>document.getElementById(`addJourneyStep${item.order_nr}`).showModal()}>
                        <FontAwesomeIcon icon={"add"} className="text-lg"/>
                    </button>
                    <ConfigureJourneyStepModal currentOrder={item.order_nr} isNew={true} />
                    <button className="btn btn-success top-20 sm:w-auto w-1/3 sm:mx-0 mx-auto "
                            onClick={()=>document.getElementById(`configureJourneyStep${item.id}`).showModal()}>
                        <FontAwesomeIcon icon={"gear"} className=""/>
                    </button>
                    <ConfigureJourneyStepModal item={item}/>
                </div>
            </div>
            <div className="hero-content break-all text-center text-neutral-content bg-gray-400 bg-opacity-30 shadow-lg hover:shadow-accent rounded-2xl w-full h-1/2">
                <div className="max-w-sm lg:max-w-full">
                    <h1 className="mb-5 text-3xl lg:text-5xl font-bold text-gray-300 underline decoration-accent ">{item.goal}</h1>
                    <p className="mb-5 text-xl text-white" dangerouslySetInnerHTML={{__html: item.strategy.replaceAll("\n", "<br />")}}></p>
                    {item.value !== "" &&
                        <div className="rounded-lg px-4 bg-emerald-500 bg-opacity-10 py-5 ring-emerald-500 ring shadow-accent shadow-lg mt-12">
                            <h1 className="text-2xl mb-3 text-white underline decoration-accent">Vermögenswert: </h1>
                            <b className="text-xl text-white"
                               dangerouslySetInnerHTML={{__html: item.value.replaceAll("\n", "<br />")}}></b>
                        </div>}
                    {/*<button className="btn btn-success mt-10">Bearbeiten</button>*/}
                </div>
            </div>
        </div>
    )
}

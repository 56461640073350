import '../../../css/App.css'
import React, { useState, useEffect } from "react";
import DropdownSubcat from "./DropdownSubcat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from 'axios';

function PersonInformationHandleDropdown({allCats, userCats, searchInput, updatePersonInformation, createSubcat}) {
    const [visibleInformation, setVisibleInformation] = useState([])
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        const unapproved = userCats.filter(cat => cat.subcategory.approved === 0).map(cat => cat.subcategory)
        const withApproved = allCats.concat(unapproved)
        setVisibleInformation(withApproved)
        setAllCategories(withApproved)
    }, [allCats, userCats])

    useEffect(() => {
        const allInf = []
        allCategories.forEach(sub => {
            if(sub.name.toLowerCase().includes(searchInput.toLowerCase())){
                allInf.push(sub)
            }
            else if(searchInput === ""){
                allInf.push(sub)
            }
        })
        setVisibleInformation(allInf)
    }, [searchInput, allCats])

    return (
        <div>
            {visibleInformation.map((subcat, index) => (
                <DropdownSubcat key={index} label={subcat.name}
                                checked={userCats.some(s => s.subcategory.name === subcat.name)}
                                updateChecked={(val) => updatePersonInformation(subcat.id, val)}
                />
            ))}
            {visibleInformation.length === 0 && searchInput !== "" &&
                <div onClick={() => createSubcat()} className="btn btn-primary mt-5"><FontAwesomeIcon icon={"plus"} /> {searchInput}</div>
            }
        </div>
    );
}

export default PersonInformationHandleDropdown;

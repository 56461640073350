import '../../css/App.css'
import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function DropdownGroup({title, children, labelClasses}) {
    return (
        <div className={"dropdown "}>
            <label tabIndex={0} className={"btn " + labelClasses}>{title}</label>

            <ul tabIndex={0} className="dropdown-content z-[1] min-w-[150px] menu p-2 shadow bg-base-300 rounded-box ">
                {children}
            </ul>
        </div>
    );
}

export default DropdownGroup;

import {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ProfileNavigation(){
    const navigate = useNavigate();
    const location = useLocation();

    const isHomeRoute = location.pathname === "/"
    const isPublicRoute = location.pathname === "/profile/desc"
    const isPersonalRoute = location.pathname === "/profile/journey"
    const isSettingsRoute = location.pathname === "/profile/settings"

    return (
        <div className="btm-nav container mx-auto rounded-t-lg">
            {/*bg-accent bg-opacity-20*/}
            <button className={`${isHomeRoute && "active"}`} onClick={() => navigate("/")}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
                <span className="btm-nav-label hidden md:block">Home</span>
            </button>
            <button className={`${isPublicRoute && "active"}`} onClick={() => navigate("/profile/desc")}>
                <FontAwesomeIcon  icon={"globe"}/>
                {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>*/}
                <span className="btm-nav-label hidden md:block">Öffentlich</span>
            </button>
            <button className={`${isPersonalRoute && "active"}`} onClick={() => navigate("/profile/journey")}>
                <FontAwesomeIcon  icon={"user"}/>
                {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>*/}
                <span className="btm-nav-label hidden md:block">Persönlich</span>
            </button>
            <button className={`${isSettingsRoute && "active"}`} onClick={() => navigate("/profile/settings")}>
                <FontAwesomeIcon icon={"gear"}/>
                {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>*/}
                <span className="btm-nav-label hidden md:block">Settings</span>
            </button>
        </div>
        // <div className="join grid grid-cols-2 navbar">
        //     <button className={`group join-item btn btn-primary ${!isPublicRoute && "bg-opacity-25"}`} onClick={() => navigate("/profile/desc")}>
        //         <span className="lg:underline decoration-sky-500 underline-offset-4 text-white decoration-2 group-focus:animate-shake">
        //             Öffentlicher Bereich</span>
        //     </button>
        //
        //     <button className={`group join-item btn btn-secondary ${!isPersonalRoute && "bg-opacity-25"}`} onClick={() => navigate("/profile/journey")}>
        //         <span className="lg:underline decoration-pink-800 underline-offset-4 text-white decoration-2 group-focus:animate-shake">Persönlicher Bereich</span>
        //     </button>
        // </div>
    )
}

export default ProfileNavigation;

import {createBrowserRouter, Outlet, Route, useLoaderData} from "react-router-dom";
import Profile from "./components/profile/Profile";
import {Header} from "./components/Header";
import React from "react";
import Startpage from "./components/startpage/Startpage";
import Login from "./components/Login";
import Progress from "./components/registration/Progress";
import ProfileDescription from "./components/profile/description/ProfileDescription";
import ProfileDaily from "./components/profile/todos/ProfileDaily";
import PersonalJourney from "./components/profile/journey/PersonalJourney";
import PersonSettings from "./components/profile/settings/PersonSettings";
import NotLoggedIn from "./components/NotLoggedIn";
import VisitProfile from "./components/visit/VisitProfile";
import {Watchlist} from "./components/profile/Watchlist";
import {
    getImagesByUserId, getJourneyByUserId,
    getPersonByUniqueKey,
    getSuggestionsByUserId,
    getWatchlistByUserId
} from "./api/person";
import {NewApp} from "./components/NewApp";
async function loader({request: {signal}}) {
    let newUser = {}
    // const dbPerson = await getPersonByUniqueKey("fzTwe1Sqq9cEaBhqhrP9"); // unique_key
    // const dbImages = getImagesByUserId(dbPerson.id);
    // const dbWatchlist = getWatchlistByUserId(dbPerson.id)
    // const dbTodos = getTodosByUserId(dbPerson.id)
    // Todo: add Suggestions
    // const dbSuggestions = getSuggestionsByUserId(dbPerson.id);
    // const dbJourney = getJourneyByUserId(dbPerson.id);
    //  todos: await dbTodos,
    // newUser = {user: dbPerson, images: await dbImages, watchlist: await dbWatchlist, journey: await dbJourney}
    return newUser
}

const rootRouter = {
    loader,
    element: <NewApp />
}


export const router = createBrowserRouter([
    {
        path: "/",
        ...rootRouter,
        errorElement: <><Header /><h1 className="text-2xl mt-3">There was an Error rendering the elements. Please refresh!</h1></>,
        children: [
            {index: true, element: <Startpage />},
            {path: "login", element: <Login />},
            {path: "register/*", element: <Progress progress={0}/>},
            {
                path: "profile",
                element: <ProfileLayout />,
                children: [
                    {path: "desc", element: <ProfileDescription editable={true}/>},
                    {path: "daily", element: <ProfileDaily editable={true} />},
                    {path: "journey", element: <PersonalJourney />},
                    {path: "settings", element: <PersonSettings />},
                    {path: "*", element: <NotLoggedIn />}
                ]
            },
            {path: "visit/:name", element: <VisitProfile />},
            {
                path: "watchlist",
                element: <Watchlist />,
                // loader: async ({ request: {signal} }) => {
                //     const person = await getPersonByUniqueKey(localStorage.getItem("unique_key"));
                //     return getWatchlistByUserId(person.id, signal);
                // }
            }
        ]
    }
])

function ProfileLayout(){
    return (
        <>
            <Profile />
            <Outlet />
        </>
    )
}

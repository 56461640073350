import ModalDialog from "../../utils/ModalDialog";
import React, {useCallback, useEffect, useState} from "react";
import update from 'immutability-helper'
import { StepForDragging} from "./StepForDragging";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import axios from 'axios';
export function RatingModal(){
    const [stars, setStars] = useState(5);
    const [comment, setComment] = useState("");
    const [sended, setSended] = useState(false);
    const {store} = useStoreContext();

    const sendData = () => {
        axios.post(process.env.REACT_APP_API_URL_PREFIX + "/rating", {person_id: store.user.id, stars: stars, comment: comment})
            .then(res => {
                setSended(true)
                setTimeout(() => {
                    document.getElementById('rateWebsite').close()
                }, 3000)
            })
    }

    return (
        <ModalDialog title={"Bewerte unsere Arbeit!"} id={"rateWebsite"}
                     footer={<><button className="btn">Schließen</button></>}>
            <b>Wie würden Sie unsere Passiony bewerten?</b><br/>
            <div className="rating rating-lg mt-5">
                <input type="radio" name="rating" className="mask mask-star-2 bg-orange-400" onChange={() => setStars(1)} checked={stars === 1} />
                <input type="radio" name="rating" className="mask mask-star-2 bg-orange-400" onChange={() => setStars(2)} checked={stars === 2} />
                <input type="radio" name="rating" className="mask mask-star-2 bg-orange-400" onChange={() => setStars(3)} checked={stars === 3} />
                <input type="radio" name="rating" className="mask mask-star-2 bg-orange-400" onChange={() => setStars(4)} checked={stars === 4} />
                <input type="radio" name="rating" className="mask mask-star-2 bg-orange-400" onChange={() => setStars(5)} checked={stars === 5}/>
            </div>
            <br/>
            <div className="mt-10"><b>Kommentar (optional): </b></div>
            <textarea className="textarea mt-4 w-1/2 mx-auto textarea-bordered focus:textarea-primary" value={comment}
                      placeholder="Geben Sie ihre Meinung ab" onChange={(e) => setComment(e.target.value)}></textarea><br />
            <button className={`mt-5 btn btn-success ${sended && "btn-disabled"}`} onClick={sendData}>Abschicken</button>
            {sended && <div className="alert alert-success mt-4">Vielen Dank für Ihre Bewertung</div>}
        </ModalDialog>
    )
}

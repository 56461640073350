import {Link} from "react-router-dom";
import Vision1 from '../../images/visionboard1.jpg'
import {useEffect, useState} from "react";
import {useStoreContext} from "../hooks/useStoreContext";

const motivation = ["ENTWICKLE DICH WEITER", "DU BIST STÄRKER, ALS DU DENKST", "GLAUBE AN DICH!", "DU SCHAFFST DAS!", "JUST BEGIN!",
    "DREAM BIG!", "STAY STRONG!", "KÄMPFE WEITER"]

function WelcomePersonal(){
    const [banner, setBanner] = useState("");
    const [todos, setTodos] = useState(0);
    const {store, dispatch} = useStoreContext();

    useEffect(() => {
        if(store.images && store.images.profile){
            const profilePics = store.images.profile
            const possibleBanner = profilePics.filter(pic => pic['desc_sub_type'] === "visionboard")
            if(possibleBanner.length > 0){
                setBanner(possibleBanner[0].images[0])
            }else {
                setBanner(Vision1)
            }
            setTodos(store.todos.filter(todo => todo.completed === 0).length)
        }
    }, [store.images, store.todos])

    return (
        <div>
            <div className="hero animate-fade-left animate-once animate-ease-in-out animate-alternate" style={{backgroundImage: `url(${banner})`}}>
                <div className="hero-content p-20 lg:p-40 text-center">
                    <div className="max-w-md relative">
                        <div className="bg-yellow-500 w-80 h-80 hidden xl:block absolute bottom-5 -left-6 rounded-full
                                    mix-blend-multiply filter opacity-60 blur-xl animate-blob animation-delay-2000"></div>
                        <div className="bg-purple-700 w-72 h-72 xl:block absolute hidden rounded-full
                                    -right-5 bottom-2 mix-blend-multiply filter opacity-60 blur-xl animate-blob"></div>
                        <h1 className="lg:text-5xl text-2xl box-decoration-clone rounded-lg px-3 py-2 font-bold
                        bg-gradient-to-r from-purple-700 to-pink-600 text-gray-400
                        animate-jump-in animate-once animate-duration-[2000ms] animate-ease-in-out">
                            {motivation[Math.floor(Math.random() * motivation.length)]}
                        </h1>

                        <br/>
                        <Link to={"/profile/journey"}>
                            <button className="btn btn-primary mt-11 shadow-2xl btn-lg animate-wiggle animate-infinite
                            animate-duration-2000 animate-ease-linear animate-normal animate-fill-forwards ">
                                Zu meinen Todos
                                <div className="badge absolute -top-2 -right-4 badge-lg rounded-2xl badge-accent">{todos}</div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomePersonal

import '../css/App.css';
import ProfileImage from '../images/profile.png';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useState} from "react";
import {useLocalStorage} from "./hooks/useLocalStorage";
import {useStoreContext} from "./hooks/useStoreContext";
import ProfileNavigation from "./profile/ProfileNavigation";
import {STORE_ACTIONS} from "../StoreProvider";
import {useCookies} from "react-cookie";
import {userLogout} from "../api/person";

export function Header() {
    const navigator = useNavigate();
    const {store, dispatch, theme} = useStoreContext();
    const location = useLocation();
    const [profilePic, setProfilePic] = useState("");
    const [cookies, setCookie] = useCookies(["token"]);

    useEffect(() => {
        if(store.images.profile){
            const profile = store.images.profile.filter(img => img.desc_sub_type === "profile_picture")[0].images[0]
            setProfilePic(profile)
        }
    }, [store.images])

    const toggleDarkMode = () => {
        let mode = theme.theme === "light" ? "dark" : "light";
        theme.setTheme(mode);
    }

    const logout = () => {
        const performLogout = async () => {
            await userLogout(cookies.token)
                .then((res) => {
                    console.log("DATA", res)
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "token", value: ""}})
                    setCookie("token", "", {path: "/"})
                    navigator("/")
                })
        }
        performLogout().finally();
    }

    return (
        <div className="flex items-center justify-center">
        <div className="Header fixed top-0 z-40 container">
            <div className="navbar bg-base-300 rounded-t-lg">
                <div className="flex-1 justify-center">
                    <Link to={"/"} className="btn btn-primary bg-opacity-30 border-none px-10 normal-case text-xl text-white">Passiony</Link>
                </div>
                <div className="flex-none ">
                    <label className="swap swap-rotate mr-5">

                        {/* this hidden checkbox controls the state */}
                        <input type="checkbox" onChange={toggleDarkMode} checked={theme.theme === "light"}/>

                        {/* sun icon */}
                        <svg className="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>

                        {/* moon icon */}
                        <svg className="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>

                    </label>
                    {cookies.token === "" ?
                        <div>
                            <Link to='/login'><button type="button" className="btn btn-outline-light me-2">Login</button></Link>
                            <Link to='/register/0'><button type="button" className="btn btn-warning">Sign-up</button></Link>
                        </div>
                        :
                        <div className="dropdown dropdown-end dropdown-hove">
                            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
                                <div className="w-10 rounded-full">
                                    <img src={profilePic}/>
                                </div>
                                {/*<FontAwesomeIcon icon="chevron-down" size="2x" className="inline"/>*/}
                            </label>
                            <ul tabIndex="0"
                                className="menu menu-sm dropdown-content z-[1] p-2 shadow bg-base-300 rounded-box px-6 items-center">
                                <Link to={"/profile/journey"} className="w-full">
                                    <button className="btn btn-ghost w-full text-xl">Profil</button>
                                </Link>
                                <Link to={"/watchlist"} className="w-full">
                                    <button className="text-xl btn btn-ghost w-full">Merkliste</button>
                                </Link>
                                <Link to={"/profile/settings"} className={"w-full"}>
                                    <button className="btn btn-ghost w-full text-xl">Einstellungen</button>
                                </Link>
                                {/*TOdo: implement logout route*/}
                                <Link className={"text-xl text-error hover:text-error mt-1 w-full"} to="/"
                                      onClick={logout}>
                                    <button className="btn btn-ghost w-full text-xl">Logout</button>
                                </Link>
                            </ul>
                        </div>
                    }
                </div>
            </div>
            {cookies.token !== "" && <ProfileNavigation />}
        </div>
        </div>
    );
}

import '../../../css/App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import TodoListItem from "./TodoListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalDialog from "../../utils/ModalDialog";
import DropdownGroup from "../../utils/DropdownGroup";
import CreateToDo from "./CreateToDo";
import {getTodoTypes} from "../../../api/types";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import {getTodosByUserIdAndDate} from "../../../api/todos";

function ToDos({ date }) {
    const {store, dispatch} = useStoreContext();
    const [todos, setTodos] = useState([])
    const [visibleTodos, setVisibleTodos] = useState([])
    const [searchText, setSearchText] = useState("");
    const [todoTypes, setTodoTypes] = useState([])

    useEffect(() => {
        const fetchTodos = async () => {
            const todos = await getTodosByUserIdAndDate(store.token, store.user.id, date);
            setTodos(todos)
            setVisibleTodos(todos)
        }
        if(store.user.id && date.startsWith(`2`)){ // Todo check whether there is the Date() and not YYYY-MM-DD currently with startsWith
            fetchTodos().finally();
        }
    }, [date, store.user, store.todos])

    useEffect(() => {
        const fetchTodos = async () =>{
            const todoTypes = await getTodoTypes();
            setTodoTypes(todoTypes)
        }
        if(store.todos){
            fetchTodos().finally();
        }
    }, [store.todos])

    const handleSearchChange = (e) => {
        const search = e.target.value;
        setSearchText(search)
        const items = todos.filter(t => t.name.toLowerCase().includes(search.toLowerCase()))
        setVisibleTodos(items)
    }

    const handleTodoChange = () => {
        const fetchTodos = async () => {
            const dbTodos = await getTodosByUserIdAndDate(store.token, store.user.id, date)
            dispatch({type: STORE_ACTIONS.SET, payload: {key: "todos", value: dbTodos}})

            // store.update({prop: 'todos', value: dbTodos})
        }
        fetchTodos().finally()
        return () => {
            console.log("test")
        }
    }

    return (
        <div className="ToDos ">
            <input type="text" placeholder="Suche..." value={searchText} onChange={handleSearchChange}
                   className="input input-bordered focus:input-primary lg:w-full max-w-xs" />
            <CreateToDo date={date} todoTypes={todoTypes}/>
            <div className="overflow-x-auto w-full md:w-3/4 2xl:w-full mx-auto mt-3 h-96">
                <table className="table bg-base-100 table-pin-rows">
                    {/* head */}
                    <thead >
                    <tr>
                        <th>
                            <label>
                                {/*<input type="checkbox" className="checkbox" />*/}
                            </label>
                        </th>
                        <th>Task</th>
                        <th>Zeit</th>
                        <th>Typ</th>
                        <th className="hidden lg:table-cell">Erstellt</th>
                        <th className="hidden lg:table-cell"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {visibleTodos.map((todo, index) => (
                        <TodoListItem date={date} initialTodo={todo} key={index} change={handleTodoChange}/>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default ToDos;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/tailwind.css';
// import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import { CookiesProvider } from "react-cookie";
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import 'jquery/dist/jquery';
import 'popper.js/dist/umd/popper';
import 'bootstrap/dist/js/bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faMedal,
    faGear,
    faEdit,
    faX,
    faTrashCan,
    faPlus,
    faPencil,
    faChevronDown,
    faThumbtack,
    faUser,
    faCalendarDay,
    faBullseye,
    faGlobe,
    faListOl,
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import {NewApp} from "./components/NewApp";
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import {StoreProvider} from "./StoreProvider";

library.add(faMedal, faEye, faEyeSlash, faListOl, faGear, faEdit, faX, faTrashCan, faPlus, faPencil, faChevronDown, faThumbtack, faUser, faCalendarDay, faBullseye, faGlobe);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <React.StrictMode>
            <CookiesProvider>
                <StoreProvider>{/*<RouterProvider router={router}/>*/}
                    <App />
                </StoreProvider>
            </CookiesProvider>
        </React.StrictMode>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

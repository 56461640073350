import {createContext, useContext, useReducer} from "react";
import {useLocalStorage} from "./components/hooks/useLocalStorage";
import {useGetSetState} from "react-use";

export const StoreContext = createContext(null);

export const STORE_ACTIONS = {
    SET: "set",
    ADD_JOURNEY_STEP: "add-journey-step",
    UPDATE_JOURNEY_STEP: "update-journey-step",
    DELETE_JOURNEY_STEP: 'delete-journey-step',
    UPDATE_USER_DESC_VAL: "update-user-desc-val"
}

function reducer(state, action){
    switch (action.type){
        case STORE_ACTIONS.SET:
            return {...state, [action.payload.key]: action.payload.value};
        case STORE_ACTIONS.UPDATE_USER_DESC_VAL:
            return state;
        case STORE_ACTIONS.ADD_JOURNEY_STEP:
            return {...state, journey: [...state.journey, action.payload.step]}
        case STORE_ACTIONS.UPDATE_JOURNEY_STEP:
            return {...state, journey: state.journey.map(step => {
                if(step.id === action.payload.step.id){
                    return action.payload.step
                }
                return step
                })
            }
        case STORE_ACTIONS.DELETE_JOURNEY_STEP:
            return {...state, journey: state.journey.filter(item => item.id !== action.payload.itemId)}
        default:
            throw new Error("invalid action")
    }
}

const initialState = {
    user: {
        information: {
            changesInLife: {subcategories: []},
            commitment: {subcategories: []},
            expandSkills: {subcategories: []},
            impulsion: {subcategories: []},
            interests: {subcategories: []},
            motifs: {subcategories: []},
            possibilities: {subcategories: []},
            selfDesc: {subcategories: []},
            skills: {subcategories: []},
            wayToGoal: {subcategories: []}
        }
    },
    images: {},
    watchlist: [],
    todos: [],
    suggestions: [],
    journey: [],
    unique_key: "",
    loading: true,
    token: "",
}

export function StoreProvider({ children }){
    const [state, dispatch] = useReducer(reducer, initialState)
    const [theme, setTheme] = useLocalStorage("theme", "dark");
    const [ratingShown, setRatingShown] = useLocalStorage("ratingShown", false)

    return (
        <StoreContext.Provider value={{store: state, dispatch, theme: {theme, setTheme}, rating: {ratingShown, setRatingShown}}}>
            {children}
        </StoreContext.Provider>
    )
}

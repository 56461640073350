import '../../css/App.css';
import ProfilePicture from "../../images/profile.png";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileInputGroup from "../utils/FileInputGroup";
import InputFileUpload from "../utils/InputFileUpload";
import axios from "axios";
import {useStoreContext} from "../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../StoreProvider";
import {uploadProfileVisionboardPic} from "../../api/person";


function ProfileInCol({user, editing=false, img}) {
    const [image, setImage] = useState();
    const {store, dispatch} = useStoreContext();

    useEffect(() => {
        if(img && img[0].images.length > 0){
            setImage(img[0].images[0])
        }
    }, [img])

    const handleProfilePic = (e) => {
        const formData = new FormData;
        formData.append('images[]', e.target.files[0])
        formData.append('id', user.id)
        formData.append('desc_type', 'profile');
        formData.append('desc_sub_type', 'profile_picture');

        const performUpload = async () => {
            await uploadProfileVisionboardPic(store.token, formData)
                .then(res => {
                    setImage(URL.createObjectURL(e.target.files[0]))
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "images", value: {...store.images,
                                profile: store.images.profile.map(p => {
                                    if(p.desc_sub_type === "profile_picture"){return {...p, images: res}}else{return p}
                                })}}})
                });
        }
        performUpload().finally();
    }

    return (
        <div className="personalInformation">
            {editing ?
                <div className="avatar group ">
                    <div className="w-44 rounded-full">
                        <img src={image} />
                    </div>
                    <div className="opacity-0 bg-black absolute text-center rounded-full group-hover:opacity-50 w-full h-full"></div>
                    <label htmlFor="profilePicUpload" className="btn btn-primary absolute bottom-0 right-0 group-hover:block hidden">
                        <FontAwesomeIcon icon={"pencil"} className="mt-4"/></label>
                    <InputFileUpload id="profilePicUpload" update={handleProfilePic} classes={"hidden"} />
                </div>
                :
                <div className="avatar">
                    <div className="w-44 rounded-full">
                        <img src={image} />
                        {/*Todo: Default Profile Picture kommt von der anderen Komponente und wird hier nicht standardmäßig gesetzt -> sonst gibt es immer Sprünge*/}
                    </div>
                </div>
            }
            <div className="mt-4 text-xl">
                <div className="mt-3 font-bold text-2xl">{user.username}</div>
                <div className="mt-3">{user.firstname} {user.lastname}</div>
                <div className="mt-3">{user.email}</div>
                {/*<div className="badge badge-info mt-5">Diamant-Liga</div>*/}
                {/* TODO: following & followed needs to be done*/}
            </div>
        </div>
    );
}

export default ProfileInCol;

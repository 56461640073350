import ModalDialog from "../../utils/ModalDialog";
import React from "react";

export function ConfigureBackgroundModal(){
    return (
        <ModalDialog title={"Hintergrund konfigurieren"} id={"configureBackground"} footer={<button className="btn">Close</button>}>
            <div className="mt-5">
                <h1 className="text-xl font-bold">Eigenes Hintergrund Bild hochladen: </h1>
                <input type="file" className="file-input file-input-primary w-full max-w-xs mt-6" />
                <hr className="mt-8"/>
                <h1 className="text-xl font-bold mt-8">Muster auswählen: </h1>
            </div>
        </ModalDialog>
    )
}

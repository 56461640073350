import ModalDialog from "../../utils/ModalDialog";
import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import axios from "axios";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import {createTodo} from "../../../api/todos";

export function CreateTodoModal({allTypes, selectedDate}){
    const {store, dispatch} = useStoreContext();
    const todoNameRef = useRef(null);
    const [selectedType, setSelectedType] = useState(1);
    const [time, setTime] = useState(new Date().toLocaleTimeString().slice(0,5)); // nur HH:mm
    const [date, setDate] = useState();

    useEffect(() => {
        setDate(selectedDate)
    }, [selectedDate])

    const handleSave = () => {
        const todoName = todoNameRef.current.value;

            const currMom = moment().format("YYYY-MM-DD");
            const data = {userId: store.user.id, todoName: todoName, typeId: selectedType, time: time, created_at: date, updated_at: date}
            const performCreation = async () => {
                await createTodo(store.token, data)
                    .then(res => {
                        dispatch({type: STORE_ACTIONS.SET, payload: {key: "todos", value: [...store.todos, res]}})
                        todoNameRef.current.value = "";
                    })
            }
        if(todoName !== ""){
            performCreation().finally();
        }
    }

    const colors = {
        amber: "checked:bg-amber-400", green: "checked:bg-green-600", teal: "checked:bg-teal-400", rose: "checked:bg-rose-400", purple: "checked:bg-purple-400"}

    return (
        <ModalDialog id={"create_todo_modal"} title={"Neues Todo erfassen"} footer={
            <div><button className="btn btn-neutral">Schließen</button><button className="btn btn-primary ml-4" onClick={handleSave}>Speichern</button></div>}>
            <input type="text" placeholder="Name" ref={todoNameRef}
                   className="input input-bordered focus:input-primary w-3/4 mt-3" /><br/>
            <div className="grid grid-cols-3 gap-3 w-3/4 mx-auto">
                <h2 className="mt-7 text-xl ">Wähle eine Uhrzeit: </h2>
                <input type="time" name="uhrzeit" value={time} onChange={(e) => setTime(e.target.value)}
                       className={"col-span-2 mt-5 w-full mx-auto text-center input input-bordered focus:input-primary"} />
            </div>
            <div className="grid grid-cols-3 gap-3 w-3/4 mx-auto">
                <h2 className="mt-7 text-xl ">Wähle ein Datum: </h2>
                <input type="date" name="uhrzeit" value={date} onChange={(e) => setDate(e.target.value)}
                       className={"col-span-2 mt-5 w-full mx-auto text-center input input-bordered focus:input-primary"} />
            </div>
            <div className="flex justify-center">
                <div className="w-1/2 mt-6 ">
                    {allTypes.map((type, index) => (
                        <div className="form-control mt-3" key={type.id}>
                            <label className="label cursor-pointer">
                                <span className="label-text text-lg">{type.name}</span>
                                <input type="radio" name=""
                                       onChange={() => setSelectedType(type.id)}
                                       className={`radio ${colors[type.color]}`} checked={selectedType === type.id} />
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </ModalDialog>
    )
}

import axios from "axios";
import {authConfig} from "./person";

export async function createTodo(token, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + '/person/todos/', data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function checkTodo(token, todo_id, data){
    return new Promise((resolve, reject) => {
        axios.put(
            process.env.REACT_APP_API_URL_PREFIX + '/person/todos/' + todo_id, data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function editTodo(token, todo_id, data){
    return new Promise((resolve, reject) => {
        axios.put(
            process.env.REACT_APP_API_URL_PREFIX + '/person/todos/' + todo_id, data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function deleteTodo(token, todo_id){
    return new Promise((resolve, reject) => {
        axios.delete(
            process.env.REACT_APP_API_URL_PREFIX + '/person/todos/' + todo_id, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function getTodosByUserIdAndDate(token, userid, date){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + `/person/todos/${userid}/${date}`, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    })
}

import '../css/App.css';
import {Outlet, RouterProvider, useLoaderData} from "react-router-dom";
import React, {createContext, useEffect, useState} from "react";
import { useCookies } from 'react-cookie';
import {useStore} from "../userStore";
import {
    getImagesByUserId,
    getPersonByUniqueKey,
    getSuggestionsByUserId,
    getWatchlistByUserId
} from "../api/person";
import {router} from "../router";
import {useLocalStorage} from "./hooks/useLocalStorage";
import {Header} from "./Header";
import {getJourneyByUserId} from "../api/journey";

export const StoreContext = createContext("");

export function NewApp() {
    const [cookies, setCookie, removeCookie] = useCookies(['UniqueKey', "theme"]);
    const [unique_key, setUnique_key] = useState(cookies.UniqueKey);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false)
    const [theme, setTheme] = useLocalStorage("theme", "dark");
    const [uniqueKey, setUniqueKey] = useLocalStorage("unique_key", null);

    useEffect(() => {
        console.log(theme)
    }, [theme])

    const userStore = useStore(); // not usable

    useEffect(() => {
        console.log(unique_key)
        const fetchUser = async () => {
            let newUser = {}
            const dbPerson = await getPersonByUniqueKey(unique_key);
            userStore.update({prop: 'user', value: dbPerson});
            const dbImages = await getImagesByUserId(dbPerson.id);
            const dbWatchlist = await getWatchlistByUserId(dbPerson.id)
            // const dbTodos = await getTodosByUserId(dbPerson.id)
            const dbSuggestions = await getSuggestionsByUserId(dbPerson.id);
            const dbJourney = await getJourneyByUserId(dbPerson.id);


            userStore.update({prop: 'images', value: dbImages});
            userStore.update({prop: 'watchlist', value: dbWatchlist});
            // userStore.update({prop: 'todos', value: dbTodos});
            userStore.update({prop: 'suggestions', value: dbSuggestions});
            userStore.update({prop: 'journey', value: dbJourney});

            // todos: dbTodos,
            newUser = {user: dbPerson, images: dbImages, watchlist: dbWatchlist, journey: dbJourney}
            setUser(newUser)
        }

        if (unique_key !== "") {
            fetchUser().finally(() => {setLoading(false);userStore.update({prop: "loading", value: false})});
        }else {
            userStore.update({prop: "loading", value: false})
            setLoading(false)
            userStore.reset();
        }
    }, [unique_key])

  return (
    <div className="App min-h-screen " data-theme={theme}>
        <Header uniqueKey={unique_key} setUniqueKey={() => setUniqueKey("")}/>
        <StoreContext.Provider value={{store: userStore, theme: {theme, setTheme}}}>
            <Outlet />
        </StoreContext.Provider>
    </div>
  );
}

import ProfileNavigation from "../ProfileNavigation";
import React, {useEffect, useState} from "react";
import primaryDots from "../../../images/StyleImprovement/primaryDots.png";
import regularDots from "../../../images/StyleImprovement/dotsRegular.png";
import randomLines from "../../../images/StyleImprovement/randomLines.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
// import vision1 from '../../../images/visionboard1.jpg'
import ModalDialog from "../../utils/ModalDialog";
import {ConfigureBackgroundModal} from "../../contents/modalContents/ConfigureBackgroundModal";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import {editDescription} from "../../../api/description";

function ProfileDescription({editable=false}){
    const {store, dispatch} = useStoreContext();
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [descVal, setDescVal] = useState("");
    const [background, setBackground] = useState("")

    useEffect(() =>{
        setLoading(store.loading)
        if(store.user.desc){
            setDescVal(store.user.desc.description)
        }
        if(store.images.profile){
            setBackground(store.images.profile.filter(a => a.desc_sub_type === "visionboard")[0].images[0]) // it was store.images.background where dst = "description"
        }
    }, [store.user, store.images])

    const handleSaveDesc = () => {
        const performSaving = async () => {
            const data = ["description", descVal]
            await editDescription(store.token, store.user.id, data)
                .then(res => {
                    setEditing(false)
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "user", value: {...store.user, ["desc"]: {...store.user["desc"], ["description"]: descVal}}}})
                })
        }
        performSaving().finally();
    }

    return (
        <div className="hero min-h-screen bg-base-200  relative" style={{backgroundImage: `url(${background})`}}>
            {/*<img className="absolute z-0 min-h-screen opacity-50 " src={background} />*/}
            {/*<img className="blur-3xl max-h-screen opacity-40 select-none" src={primaryDots} />*/}
            {/*<img className="max-h-screen opacity-50 blur-2xl absolute rotate-12 bottom-20 left-20 select-none" width={400} src={randomLines} />*/}
            <div className="hero-overlay bg-opacity-50"></div>
            <div className="hero-content flex-col lg:flex-row md:mt-20 lg:mt-0 relative z-0 backdrop-blur rounded-3xl p-16">
                <div className="w-full md:px-24 lg:px-15 xl:px-0">
                    <h1 className="text-3xl lg:text-5xl font-bold animate-fade-left text-gray-300">Meine Beschreibung</h1>
                    <div className={"mt-10 animate-fade-right bg-primary bg-opacity-10 rounded-lg py-5 px-10 ring-primary ring shadow-primary shadow-xl"}>
                        {editing ?
                            <textarea cols={100} className="textarea w-full text-white" placeholder="Description" value={descVal}
                                      onChange={(e) => setDescVal(e.target.value)}></textarea>:
                            <div className="text-white" dangerouslySetInnerHTML={{__html: descVal}}></div>
                        }
                        {editable && (
                            <div className="mt-6 w-full">
                                <hr className="border-primary blur-sm border-4" />
                                {editing ?
                                    <div className="mt-5 flex justify-end">
                                        <button className="btn btn-error" onClick={() => {setEditing(false); setDescVal(store.user.desc.description)}}>Abbrechen</button>
                                        <button className="btn btn-success ml-3" onClick={handleSaveDesc}>Speichern</button></div>
                                :
                                    <div className="mt-5 flex justify-end">
                                        <button className="btn btn-neutral" onClick={() => setEditing(true)}><FontAwesomeIcon icon="pencil"/></button>

                                    </div>}
                            </div>
                        )}
                    </div>
                </div>
                <div className="divider lg:divider-horizontal "></div>
                <div className="w-full ">
                    <div className="rounded-lg bg-emerald-500 bg-opacity-10 py-5 ring-emerald-500 ring shadow-accent shadow-lg px-6 ">
                        <h1 className="text-xl text-white">Interessen</h1>
                        {store.user?.information?.interests.subcategories.map((interest, index) => (
                            <div key={index} className="badge badge-primary px-5 py-3 mt-6 mr-4">{interest.subcategory.name}</div>
                        ))}
                    </div>
                    <div className="rounded-lg bg-emerald-500 bg-opacity-10 py-5 ring-emerald-500 ring shadow-accent shadow-lg mt-12">
                        <h1 className="text-xl text-white">Fähigkeiten</h1>
                        {store.user?.information?.skills.subcategories.map((interest, index) => (
                            <div key={index} className="badge badge-primary px-5 py-3 mt-6 mr-4">{interest.subcategory.name}</div>
                        ))}
                    </div>
                    <div className="rounded-lg bg-emerald-500 bg-opacity-10 py-5 ring-emerald-500 ring shadow-accent shadow-lg mt-12">
                        <h1 className="text-xl text-white">Eigene Beschreibung</h1>
                        {store.user?.information?.selfDesc.subcategories.map((interest, index) => (
                            <div key={index} className="badge badge-primary px-5 py-3 mt-6 mr-4">{interest.subcategory.name}</div>
                        ))}
                    </div>
                </div>

                {/*TODO: COMMENT BOTH BUTTONS IN FOR EDITING (needs changes in DB)*/}
                {/*<div className="lg:hidden flex justify-center">*/}
                {/*    <button className="btn btn-success bottom-10 mt-6" onClick={()=>document.getElementById('configureBackground').showModal()}>*/}
                {/*        <FontAwesomeIcon icon={"gear"} className=""/>Hintergrund konfigurieren</button>*/}
                {/*</div>*/}

            </div>
            {/*<div className="hidden lg:flex justify-center">*/}
            {/*    <button className="btn btn-success absolute bottom-10" onClick={()=>document.getElementById('configureBackground').showModal()}>*/}
            {/*        <FontAwesomeIcon icon={"gear"} className=""/>Hintergrund konfigurieren</button>*/}
            {/*</div>*/}
            <ConfigureBackgroundModal />

        </div>
    )
}

export default ProfileDescription;

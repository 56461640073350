import '../../css/App.css';
import ProfileSuggestions from "./ProfileSuggestions";
import WelcomePassiony from "./WelcomePassiony";
import WelcomePersonal from "./WelcomePersonal";
import {useStoreContext} from "../hooks/useStoreContext";

function Startpage({loggedIn}) {
    const {store} = useStoreContext();

    return (
        <div className="Startpage mt-16">
            {loggedIn ?
                <WelcomePersonal />
                :
                <WelcomePassiony />
            }
            <div className={"grid md:grid-cols-2 lg:grid-cols-3 mt-6 gap-8 px-4 container mx-auto mb-10"}>
                {store.unique_key !== "" && store.suggestions.map((suggest) => (
                    <ProfileSuggestions key={suggest.id} suggestionId={suggest.id}/>
                ))}
            </div>
        </div>
    );
}

export default Startpage;

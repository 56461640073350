import '../../css/App.css';
import ProfileNavigation from "./ProfileNavigation";

function Profile() {
    return (
        <div className={`Profile container mx-auto`} >
            {/*<div className="menu-horizontal left-5 bg-base-100 rounded-box md:absolute z-10 group md:mt-20 py-2 px-5 group pr-1 hover:pr-6 mb-3">*/}
            {/*    <ul className="steps steps-vertical ">*/}
            {/*        <li className="step step-primary"><span className="group-hover:block hidden">Dies ist mein Titel</span></li>*/}
            {/*        <li className="step step-primary"><span className="group-hover:block hidden">Register</span></li>*/}
            {/*        <li className="step"><span className="group-hover:block hidden">Register</span></li>*/}
            {/*        <li className="step"><span className="group-hover:block hidden">Register</span></li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            {/*<ProfileNavigation />*/}
        </div>
    );
}

export default Profile;

import '../../../css/App.css';
import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListGroup} from "react-bootstrap";
import moment from "moment/moment";
import axios from "axios";
import {getCurrentDate} from "./ProfileDaily";
import {checkTodo, deleteTodo, editTodo} from "../../../api/todos";
import {useStoreContext} from "../../hooks/useStoreContext";

function TodoListItem({initialTodo, change, date}) {
    const [checked, setChecked] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editText, setEditText] = useState("")
    const [todo, setTodo] = useState({})
    const {store} = useStoreContext();

    useEffect(() => {
        setChecked(initialTodo.completed)
        setEditText(initialTodo.name)
        setTodo(initialTodo)
    }, [initialTodo])

    const handleChecked = () => {
        const newChecked = !checked;
        // setChecked(newChecked)
        const performChecking = async () => {
            const data = {completed: newChecked, updated_at: moment().format("YYYY-MM-DD")}
            await checkTodo(store.token, todo.id, data)
                .then(res => {
                    change()
                })
        }
        performChecking().finally();
    }

    const handleCancelEditing = () => {
        const newEditing = !editing;
        setEditText(todo.name)
        setEditing(newEditing)
    }

    const handleSaveEditing = () => {
        const newEditing = !editing;
        setEditing(newEditing)
        const performSaving = async () => {
            const data = {name: editText}
            await editTodo(store.token, todo.id, data)
                .then((data) => {
                    setTodo(data)
                    setEditText(data.name)
                    change()
                })
        }
        performSaving().finally();
    }

    const handleTodoDelete = () => {
        const performDeletion = async () => {
            await deleteTodo(store.token, todo.id)
                .then(res => {
                    change()
                })
        }
        performDeletion().finally();
    }

    const colors = {
        amber: "bg-amber-400", green: "bg-green-600", teal: "bg-teal-400", rose: "bg-rose-400", purple: "bg-purple-400"}


    return (
        <tr className="group">
            <td>
                <label>
                    <input type="checkbox" className={`checkbox ${date !== getCurrentDate() && "hidden"}`} checked={checked} onChange={handleChecked} />
                </label>
            </td>
            <td className="min-w-[150px] h-16">
                {!editing ?
                    <div className="flex items-center space-x-3 font-bold">{todo.name}</div> :
                    <div>
                        <input type="text" placeholder="Type here" value={editText} onChange={(e) => setEditText(e.target.value)}
                               className="input input-bordered  focus:input-primary w-full max-w-xs" />
                    </div>
                }

            </td>
            <td>{todo.time?.slice(0, -3)} Uhr</td>
            <td>
                <div className={`badge text-black ${colors[todo.type?.color]}`}  >{todo.type?.name}</div>
            </td>
            <td className="hidden lg:table-cell">
                <div>{moment(todo.created_at).format("DD. MMMM YYYY")}</div>
            </td>
            <td className="hidden lg:table-cell">
                {!editing ?
                    <div>
                        <span className={"btn btn-xs group-hover:opacity-100 opacity-0 btn-error float-right ms-2 "}
                              onClick={handleTodoDelete}>LÖSCHEN</span>
                        <span className={"btn btn-xs group-hover:opacity-100 opacity-0 btn-primary float-right "}
                              onClick={() => setEditing(!editing)}>BEARBEITEN</span>
                    </div>
                :
                    <div>
                        <span className={"btn btn-xs opacity-100 btn-error float-right ms-2 " }
                            onClick={handleCancelEditing}>ABBRECHEN</span>
                        <span className={"btn btn-xs opacity-100 btn-primary float-right "}
                              onClick={handleSaveEditing}>SPEICHERN</span>
                    </div>
                }
            </td>
        </tr>
    );
}

export default TodoListItem;

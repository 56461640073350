import '../../css/App.css';
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getImagesByUserId, getPersonByUsername} from "../../api/person";
import CardGroup from "../utils/CardGroup";
import ProfileInCol from "../profile/ProfileInCol";
import Loading from "../utils/Loading";
import {useStore} from "../../userStore";
import {useStoreContext} from "../hooks/useStoreContext";

function VisitProfile({}) {
    const { name } = useParams();
    const {theme} = useStoreContext();

    const [user, setUser] = useState([]);
    const [allCats, setAllCats] = useState([]);
    const [images, setImages] = useState({});
    const [loading, setLoading] = useState(true)

    const visitStore = useStore();

    useEffect(() =>{
        setLoading(true)
        const fetchData = async () => {
            var person = await getPersonByUsername(name);
            setUser(person)
            visitStore.update({prop: "user", value: person})
            setAllCats(person.information)
        }
        fetchData().finally();
    }, [name])

    useEffect(() => {
        setLoading(true)
        const fetchImages = async () => {
            const response = await getImagesByUserId(user.id);
            setImages(response)
            visitStore.update({prop:"images", value: response})
        }
        // get Image
        if(user.id){
            fetchImages().finally(() => setLoading(false));
        }
    }, [user])

    return (
        <div className="Profile mt-16 mx-auto container " >
            {loading ?
                <CardGroup title={""} background={"base-300"} classes={"rounded-t-none"}><Loading /></CardGroup>
                :
            <CardGroup title={""} background={"base-300"} classes={"rounded-t-none"}>
                    <div className={"grid grid-cols-1 gap-3 animate-fade-right " + (images.profile && images.profile[0].images[0] && "lg:grid-cols-2")} >
                        <ProfileInCol user={user} img={images?.profile?.filter(img => img['desc_sub_type'] === "profile_picture")} />
                        <img src={images.profile && images.profile.filter(img => img['desc_sub_type'] === "visionboard")[0]?.images[0]}
                             className="animate-fade-left" />
                    </div>
                    <div className="generalInformation grid grid-cols-1 2xl:grid-cols-2 gap-3 w-full lg:w-3/4 mt-10 animate-fade-right " >
                        {Object.keys(allCats).map(key => (
                            <CardGroup key={key} title={allCats[key].display_name} background={"base-100"}>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    {user.information[key].subcategories.map((subcat, index) => (
                                        <div className="badge badge-primary" key={index}>{subcat.subcategory.name}</div>
                                    ))}
                                </div>
                                {user.information[key].subcategories.length === 0 &&
                                    <div className={`${theme.theme === "dark" ? "bg-purple-800" : "bg-purple-400"} py-2 mt-4 rounded-lg w-full `}>
                                        Keine Einträge
                                    </div>}
                            </CardGroup>
                        ))}
                    </div>
            </CardGroup>
            }
        </div>
    );
}

export default VisitProfile;

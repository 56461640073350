import '../css/App.css';
import {Header} from './Header';
import {Route, BrowserRouter, Routes, Outlet, useLocation, useNavigation} from "react-router-dom";
import Login from './Login';
import Progress from './registration/Progress';
import Profile from './profile/Profile';
import Startpage from "./startpage/Startpage";
import Ranking from "./profile/Ranking";
import NotLoggedIn from "./NotLoggedIn";
import React, {createContext, useEffect, useState} from "react";
import { useCookies } from 'react-cookie';
import VisitProfile from "./visit/VisitProfile";
import {Watchlist} from "./profile/Watchlist";
import {useStore} from "../userStore";
import {
    getImagesByUserId, getPersonByToken,
    getPersonByUniqueKey,
    getSuggestionsByUserId,
    getWatchlistByUserId
} from "../api/person";
import Loading from "./utils/Loading";
import ProfileDescription from "./profile/description/ProfileDescription";
import ProfileDaily, {getCurrentDate} from "./profile/todos/ProfileDaily";
import PersonalSections from "./profile/journey/PersonalJourney";
import PersonSettings from "./profile/settings/PersonSettings";
import PersonalJourney from "./profile/journey/PersonalJourney";
import {useLocalStorage} from "./hooks/useLocalStorage";
import {STORE_ACTIONS, StoreProvider} from "../StoreProvider";
import {useStoreContext} from "./hooks/useStoreContext";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {RatingModal} from "./contents/modalContents/RatingModal";
import {getJourneyByUserId} from "../api/journey";
import {getTodosByUserIdAndDate} from "../api/todos";


function App() {
    const {store, dispatch, theme, rating} = useStoreContext();
    const [cookies, setCookie] = useCookies(["token"])
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false)

    const userStore = useStore(); // not usable

    useEffect(() => {
        if(cookies.token){
            dispatch({type: STORE_ACTIONS.SET, payload: {key:"token", value:cookies.token}})
        }
        if(!cookies.token){
            setCookie("token", "", {path: "/"})
        }
    }, [cookies]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log(rating.ratingShown);
            if (cookies.token !== "" && !rating.ratingShown){
                document.getElementById('rateWebsite').showModal();
                rating.setRatingShown(true)
            }
        }, 30000) // 5 min
        return () => clearTimeout(timeout);
    })

    useEffect(() => {
        const fetchUser = async () => {
            let newUser = {}
            const token = cookies.token;
            const dbPerson = await getPersonByToken(token);
            dispatch({type: STORE_ACTIONS.SET, payload: {key: 'user', value: dbPerson}})

            const dbImages = await getImagesByUserId(token, dbPerson.id);
            const dbWatchlist = await getWatchlistByUserId(token, dbPerson.id)
            const dbTodos = await getTodosByUserIdAndDate(token, dbPerson.id, getCurrentDate())
            // TODO: add Suggestions
            // const dbSuggestions = await getSuggestionsByUserId(dbPerson.id);
            const dbJourney = await getJourneyByUserId(token, dbPerson.id);

            dispatch({type: STORE_ACTIONS.SET, payload: {key: 'images', value: dbImages}})
            dispatch({type: STORE_ACTIONS.SET, payload: {key: 'watchlist', value: dbWatchlist}})
            dispatch({type: STORE_ACTIONS.SET, payload: {key: 'todos', value: dbTodos}})
            // TODO: add Suggestions
            // dispatch({type: STORE_ACTIONS.SET, payload: {key: 'suggestions', value: dbSuggestions}})
            dispatch({type: STORE_ACTIONS.SET, payload: {key: 'journey', value: dbJourney}})

            newUser = {user: dbPerson, images: dbImages, watchlist: dbWatchlist, todos: dbTodos, journey: dbJourney}
            // console.log(newUser)
            setUser(newUser)
        }

        if (cookies.token && cookies.token !== "") {
            fetchUser().finally(() => {setLoading(false); dispatch({type: STORE_ACTIONS.SET, payload: {key: "loading", value: false}}); userStore.update({prop: "loading", value: false})});

        }else {
            userStore.update({prop: "loading", value: false})
            setLoading(false)
            userStore.reset();
        }
    }, [cookies.token])

  return (
      <div className="App min-h-screen" data-theme={theme.theme}>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<RootLayout />}>
                        <Route index={true} element={<Startpage loggedIn={cookies.token !== ''} />}  />
                        <Route path="login" element={<Login/> } />
                        <Route path="register/*" element={<Progress progress={0}/>}/>
                        {cookies.token !== '' ?
                            <Route path="profile/" element={<ProfileLayout />}>
                                    <Route path={"desc"} element={<ProfileDescription editable={true}/>}/>
                                    {/*<Route path={"daily"} element={<ProfileDaily editable={true} />}/>*/}
                                    <Route path={"journey"} element={<PersonalJourney />}/>
                                    <Route path={'settings'} element={<PersonSettings />} />
                            </Route>
                            :
                            <Route path="profile/*" element={<NotLoggedIn />}/>
                        }
                        <Route path="visit/:name" element={<VisitProfile />} />
                        <Route path="watchlist" element={<Watchlist />} />
                    </Route>
                </Routes>
            </BrowserRouter>
          </DndProvider>
          <RatingModal />
      </div>
  );
}

export default App;

function ProfileLayout(){
    return (
        <>
            <Profile />
            <Outlet />
        </>
    )
}

function RootLayout(){
    return (
        <>
            <Header/>
            <Outlet />
        </>
    )
}

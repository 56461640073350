import {Link} from "react-router-dom";

function WelcomePassiony(){
    return (
        <div className="hero bg-gradient-to-r from-violet-900 to-violet-500 animate-fade-left animate-once animate-ease-in-out animate-alternate">
            <div className="hero-content p-20 lg:p-40 text-center">
                <div className="max-w-md">
                    <h1 className="lg:text-5xl text-2xl font-bold  animate-jump-in animate-once animate-duration-[2000ms] animate-ease-in-out">
                        WILLKOMMEN BEI PASSIONY
                    </h1>
                    <Link to={"/register"}><button className="btn btn-primary mt-11 shadow-2xl btn-lg animate-wiggle animate-infinite
                        animate-duration-2000 animate-ease-linear animate-normal animate-fill-forwards">Get Started !</button></Link>
                </div>
            </div>
        </div>
    )
}

export default WelcomePassiony

import '../../css/App.css';
import {Route, BrowserRouter, Routes, Link, useLocation} from "react-router-dom";
import React, {useState, useEffect} from "react";
import $ from 'jquery';
import {Register1, Register2, Register3, Register4, Register5, Register6} from "./RegisterChats";
import RegisterPersonalData from "./RegisterPersonalData";
import SmokeVid from '../../images/smoke_-_36098 (540p).mp4'

function Progress(props) {
    function getCurrNum(){
        return parseInt(window.location.pathname.replace("/register/", ""));
    }

    const [anzahlSteps, set_anzahlSteps] = useState(6);
    const [curr_num, setCurrNum] = useState(getCurrNum);
    const [progress, set_progress] = useState(100/anzahlSteps * curr_num);

    const location = useLocation();

    useEffect(() => {
        let currNum = getCurrNum();
        if(!currNum){currNum = 0}
        setCurrNum(currNum)
        set_progress(100/anzahlSteps * currNum)
    }, [location]);

    return (
        <div className="Progress mt-16 mx-auto container">
            <div className="card text-center rounded-t-none bg-base-200">
            <div className="py-2 mb-2 px-6 text-center">
                <progress className="progress progress-primary" value={progress} max="100"></progress>
            </div>

                <Routes>
                    <Route path="/*" element={<Register1 />}/>
                    <Route path="/1/*" element={<Register2 />}/>
                    <Route path="/2" element={<Register3 />} />
                    <Route path="/3" element={<Register4 />}/>
                    <Route path="/4" element={<Register5 />}/>
                    <Route path="/5" element={<Register6 />}/>
                    <Route path="/6" element={<RegisterPersonalData />}/>
                </Routes>
            </div>
        </div>
    );
}

export default Progress;

import axios from "axios";
import {authConfig} from "./person";
async function getAllCategories(){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/information/category')
            .then(res => {
                resolve(res.data)
            })
    });
}

async function getOneCategory(category){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/information/category/' + category)
            .then(res => {
                resolve(res.data)
            })
    });
}

async function addSubcategory(token, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + '/person/information', data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

async function deleteSubcategory(token, id){
    return new Promise((resolve, reject) => {
        axios.delete(
            process.env.REACT_APP_API_URL_PREFIX + '/person/information/'+id, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

async function createSubcategory(data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + '/information/subcategory', data )
            .then(res => {
                resolve(res.data)
            })
    });
}

export {createSubcategory, deleteSubcategory, addSubcategory, getAllCategories, getOneCategory};

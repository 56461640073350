import '../../css/App.css'
import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function PasswordInputGroup({title, placeholder, footer, change, value}) {
    const [passwordType, setPasswordType] = useState(true);
    return (
        <div className="form-control w-full relative">
            <label className="label">
                <span className="label-text">{title}</span>
            </label>
            <input onChange={(e) => change(e)} type={passwordType ? "password" : "text"} value={value} placeholder={placeholder}
                   className="input input-bordered focus:input-primary w-full peer " />
            <FontAwesomeIcon icon={passwordType ? "eye" : "eye-slash"} onClick={() => setPasswordType(prev => !prev)}
                             className="absolute bottom-8 right-3 opacity-50 peer-focus:opacity-80" />
            <label className="label">
                {footer && footer}
            </label>
        </div>

    );
}

import {useEffect, useState} from "react";

export function Typewriter({text, delay, infinite}){
    const [currText, setCurrText] = useState("");
    const [currIndex, setCurrIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (currIndex < text.length) {
            timeout = setTimeout(() => {
                setCurrText(prevText => prevText + text[currIndex]);
                setCurrIndex(prevIndex => prevIndex + 1);
            }, delay);

        } else if (infinite) { // ADD THIS CHECK
            setCurrIndex(0);
            setCurrText('');
        }

        return () => clearTimeout(timeout);
    }, [currIndex, delay, infinite, text])

    return <span>{currText}</span>
}

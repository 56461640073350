import '../../css/App.css';
import CardGroup from "../utils/CardGroup";
import {useContext, useEffect, useState} from "react";
import axios from 'axios';
import ProfileInCol from "./ProfileInCol";
import {Link, useLoaderData, useNavigation} from "react-router-dom";
import {useStoreContext} from "../hooks/useStoreContext";

export function Watchlist() {
    const { store, dispatch, theme } = useStoreContext();
    // const { state } = useNavigation();
    // const watchlist = useLoaderData();

    // const isLoading = state === "loading";

    const inProgress = true;

    if(inProgress){
        return (
            <div className="Watchlist container mt-20 mx-auto">
                <div role="alert" className="alert alert-info">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="h-6 w-6 shrink-0 stroke-current">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <span>Diese Funktion ist noch im Aufbau! Habe bitte noch etwas Geduld</span>
                </div>
            </div>
        )
    }

    return (
        <div className="Watchlist container mt-20 mx-auto">
            <div className="grid grid-cols-4 gap-5">
                {store.watchlist.map((user, index) => (
                    <div className="" key={user.id}><Link to={"/visit/" + user.user.username}>
                        <CardGroup title={""} background={"base-300"}>
                            <ProfileInCol img={[{images: [user.profile_pic]}]} user={user.user}/>
                        </CardGroup>
                    </Link></div>
                ))}
            </div>
        </div>
    );
}

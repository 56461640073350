import '../../css/App.css';
import React, {useState, useEffect} from "react";

function CardGroup({title, children, background, classes=""}) {
    return (
        <div className={`card w-full bg-${background} ${classes}`}>
            <div className="card-body items-center text-center">
                {title && <h2 className="card-title">{title}</h2>}
                {children}
            </div>
        </div>
    );
}

export default CardGroup;

import '../../css/App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import axios from 'axios';
import vision1 from '../../images/visionboard1.jpg'
import {Link} from "react-router-dom";
import {useStoreContext} from "../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../StoreProvider";


function ProfileSuggestions({suggestionId, picture}) {
    const [watched, setWatched] = useState(false);
    const {store, dispatch, theme} = useStoreContext();
    const suggestion = store.suggestions.find(suggest => suggest.id === suggestionId)

    useEffect(() => {
        !suggestion.watched ? setWatched(false) : setWatched(true)
    }, [suggestion.watched])

    const handleWatchlistChange = () => {
        const newWatched = !watched;
        if(newWatched){
            axios.post(process.env.REACT_APP_API_URL_PREFIX + "/person/watchlist", {userId: store.user.id, watched_id: suggestion.id})
                .then(res => {
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "suggestions", value: store.suggestions.map(a => a.id === suggestion.id ? {...a, watched: res.data.id} : a )}})
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "watchlist", value: [...store.watchlist, res.data]}})
                    // store.update({prop: 'suggestions', value: store.suggestions.map(a => a.id === suggestion.id ? {...a, watched: res.data.id} : a )})
                    // store.update({prop: 'watchlist', value: [...store.watchlist, res.data]})
                    setWatched(true)
                })
        }else {
            axios.delete(process.env.REACT_APP_API_URL_PREFIX + "/person/watchlist/"+suggestion.watched)
                .then(res => {
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "suggestions", value: store.suggestions.map(a => a.watched === suggestion.watched ? {...a, watched: false} : a )}})
                    dispatch({type: STORE_ACTIONS.SET, payload: {key: "watchlist", value: store.watchlist.filter(a => a.id !== suggestion.watched)}})
                    // store.update({prop: 'suggestions', value: store.suggestions.map(a => a.watched === suggestion.watched ? {...a, watched: false} : a )})
                    // store.update({prop: 'watchlist', value: store.watchlist.filter(a => a.id !== suggestion.watched)})
                    setWatched(false)
                })
        }
    }

    return (
        <div className="card bg-base-200  shadow-lg hover:shadow-purple-700 transition ">
            <div className="card-body">
                <div className="grid grid-cols-6 gap-4">
                    <div className="avatar">
                        <div className="rounded-full">
                            <img src={suggestion.profile_pic} />
                        </div>
                    </div>
                    <h2 className="font-bold col-start-3 col-span-2 text-lg mt-2 content-center">{suggestion.username}</h2>
                </div>


                <div><span className="decoration-sky-500/30 decoration-2 underline-offset-2 underline">Gemeinsamkeiten:</span>
                    <br />
                    {suggestion.informationText.map((inf,  index) => (
                        <div className="mt-2" key={index}>
                            <div className="badge badge-primary mt-2" key={index}>{inf}</div>
                        </div>
                    ))}
                    {suggestion.informationText.length === 0 && <div className={`${theme.theme === "dark" ? "bg-purple-800" : "bg-purple-400"} py-2 mt-4 rounded-lg `}>Keine Einträge</div>}
                </div>
                <div className="card-actions justify-end mt-4">
                    {store.unique_key !== "" &&
                        !watched ?
                        <div className="tooltip tooltip-success tooltip-left " data-tip="Auf die Merkliste setzen">
                            <button className={"btn btn-success transition duration-500 hover:scale-110"} onClick={handleWatchlistChange}><FontAwesomeIcon className={"rotate-45"} icon={"thumbtack"}/> </button>
                        </div>
                        :
                        <div className="tooltip tooltip-error tooltip-left " data-tip="Von der Merkliste löschen">
                            <button className={"btn btn-error transition duration-500 hover:scale-110"} onClick={handleWatchlistChange}><FontAwesomeIcon className={"rotate-45"} icon={"thumbtack"}/> </button>
                        </div>
                    }
                    <Link to={"/visit/"+suggestion.username}><button className="btn btn-primary hover:shadow-lg hover:shadow-indigo-500/50">Besuchen</button></Link>
                </div>
            </div>
        </div>
    );
}

export default ProfileSuggestions;

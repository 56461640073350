import ModalDialog from "../../utils/ModalDialog";
import React, {useCallback, useEffect, useState} from "react";
import update from 'immutability-helper'
import { StepForDragging} from "./StepForDragging";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import axios from 'axios';
import {sortJourneySteps} from "../../../api/journey";
import {authConfig} from "../../../api/person";

const style = {
    width: 400,
}
export function SortJourneyStepsModal(){
    const {store, dispatch} = useStoreContext();
    const [steps, setSteps] = useState([])

    useEffect(() => {
        setSteps(store.journey)
    }, [store.journey])

    const moveStep = useCallback((dragIndex, hoverIndex) => {
        setSteps((prevSteps) =>
            update(prevSteps, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevSteps[dragIndex]],
                ],
            }),
        )
    }, [])

    const renderStep = useCallback((step, index) => {
        return (
            <StepForDragging
                key={step.id}
                index={index}
                id={step.id}
                text={step.goal}
                moveStep={moveStep}
            />
        )
    }, [])

    const onSaveOrder = () => {
        steps.map((index, item) => {
            return {...item, order_nr: index+1}
        })
        const performSorting = async () => {
            const data = {'ids': steps.map(item => item.id)}
            await sortJourneySteps(store.token, data)
                .then(res => {
                    console.log(res)
                })
        }
        performSorting().finally();
        dispatch({type: STORE_ACTIONS.SET, payload: {key: "journey", value: steps}})
    }

    return (
        <ModalDialog title={"Steps sortieren"} id={"sortJourneySteps"}
                     footer={<><button className="btn">Schließen</button><button onClick={onSaveOrder} className="ml-4 btn btn-success">Speichern</button></>}>
            <b>Ziehe deine Ziele an den richtigen Ort!</b>
            <div className={`mx-auto mt-5`} style={style}>{steps.map((step, i) => renderStep(step, i))}</div>
        </ModalDialog>
    )
}

import '../css/App.css';
import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";

function NotLoggedIn() {
    return (
        <div className="NotLoggedIn mx-auto container mt-20">
            <h2 className="text-2xl">Du bist leider nicht eingeloggt!</h2>
            <div className="mt-6">
                <Link to={'/'}><button className="btn btn-neutral ">← Zur Startseite</button></Link>
                <Link to={'/login'}><button className="btn btn-neutral ml-2">Einloggen</button></Link>
                <Link to={'/register'}><button className="btn btn-neutral ml-2">Registrieren</button></Link>
            </div>
        </div>
    );
}

export default NotLoggedIn;

import axios from "axios";
async function getTodoTypes(){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/types/todo')
            .then(res => {
                resolve(res.data)
            })
    });
}

export {getTodoTypes};

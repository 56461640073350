import {useContext} from "react";
import {StoreContext} from "../../StoreProvider";

export function useStoreContext(){
    const value = useContext(StoreContext);
    if(value == null){
        throw new Error("Should be in StoreContext.Provider")
    }
    return value
}

import '../../../css/App.css'
import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from "axios";

function DropdownSubcat({label, checked, updateChecked}) {
    const [visChecked, setVisChecked] = useState(false)

    useEffect(() => {
        setVisChecked(checked)
    }, [checked])

    const handleCheckedChange = () => {
        const checked = !visChecked
        setVisChecked(checked)
        updateChecked(checked)
    }

    return (
        <li>
            <div className="form-control">
                <label className="label cursor-pointer">
                    <input type="checkbox" checked={visChecked} onChange={handleCheckedChange} className="checkbox" />
                    <span className="label-text ml-3">{label}</span>
                </label>
            </div>
        </li>
    );
}

export default DropdownSubcat;

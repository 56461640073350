import {useMethods} from "react-use";
import {getPersonByUniqueKey} from "./api/person";

const initialState = {
    user: {},
    images: {},
    watchlist: [],
    todos: [],
    suggestions: [],
    journey: [],
    unique_key: "",
    loading: true,
}
function createMethods(state){
    return {
        reset(){
            return {...initialState}
        },
        show() {
            return {...state}
        },
        update({prop, value}){
            // console.log({...state, [prop]: value })
            return {...state, [prop]: value }
        },
    }
}

export const useStore = () => {
    const [state, methods,] = useMethods(createMethods,initialState)
    return {state, ...methods}
}

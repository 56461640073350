import '../css/App.css';
import $ from "jquery";
import md5 from "blueimp-md5";
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import React, {useState} from "react";
import InputGroup from "./utils/InputGroup";
import {PasswordInputGroup} from "./utils/PasswordInputGroup";
import {useCookies} from "react-cookie";
import {STORE_ACTIONS} from "../StoreProvider";
import {useStoreContext} from "./hooks/useStoreContext";
import {userLogin} from "../api/person";

function Login() {
    const navigator = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [showError, setShowError] = useState(false);
    const {store, dispatch} = useStoreContext();
    const [cookies, setCookie] = useCookies(["token"])
    function tryLogin() {
        // TODO: other route + other data (auslagern)
        const performLogin = async () => {
           await userLogin(username, password)
                .then((res) => {
                    if(res.token){
                        setCookie('token', res.token, {path: "/"})
                        dispatch({type: STORE_ACTIONS.SET, payload: {key: "token", value: res.token}})
                        navigator("/")
                    }
                }).catch((res) => {
                   setShowError(true)
                   setErrorMsg(res.message)
               });
        }
        if(username !== "" || password !== "") {
            performLogin().finally()
        }

    }

    return (
        <div className="hero py-5 bg-base-200 Login px-4 mt-16 rounded-b-2xl container mx-auto">
            <div className="hero-content flex-col lg:flex-row-reverse">
                <div className="text-center lg:text-left ">
                    <h1 className="text-5xl font-bold animate-flip-up animate-once animate-duration-1000 animate-ease-in-out animate-normal">Jetzt einloggen!</h1>
                    <p className="py-6 animate-flip-down animate-once animate-duration-1000 animate-ease-in-out animate-normal">
                        Werde Teil der <span className="font-bold">PASSIONY</span>-Community und kreiere dir deinen eigenen Trainingsplan für deine persönlichen Fähigkeiten!</p>
                    {showError && <div role="alert" className="alert alert-error">
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none"
                             viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                        </svg>
                        <span>{errorMsg}</span>
                    </div>}
                </div>
                <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100 animate-fade-right animate-once animate-duration-1000 animate-ease-in-out animate-normal">
                    <div className="card-body">
                        <InputGroup placeholder={"muster"} title={"Username"} value={username} change={(e) => setUsername(e.target.value)} />
                        <PasswordInputGroup placeholder={""} title={"Passwort"} value={password} change={(e) => setPassword(e.target.value)}/>
                        <a href="#" className="label-text-alt link link-hover -mt-3">Passwort vergessen?</a>
                        <div className="form-control mt-6">
                            <button className="btn btn-primary" onClick={tryLogin} type={"submit"}>Login</button>
                            <Link to={"/register"} className="mt-2 underline cursor-pointer">Registrieren</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;

import axios from "axios";
import {authConfig} from "./person";

export async function createJourneyStep(token, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + '/person/journey/', data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function editJourneyStep(token, step_id, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + `/person/journey/${step_id}`, data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function deleteJourneyStep(token, step_id){
    return new Promise((resolve, reject) => {
        axios.delete(
            process.env.REACT_APP_API_URL_PREFIX + `/person/journey/${step_id}`, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

export async function getJourneyByUserId(token, userid){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + "/person/journey/" + userid, authConfig(token))
            .then(res => {
                resolve(res.data);
            })
    })
}

export async function sortJourneySteps(token, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + `/person/journey/sort`, data, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

import React from "react";

function InputFileUpload ({update, id="", classes}){
    return (
        <input type="file" multiple accept="image/png, image/gif, image/jpeg"
               id={id}
               onChange={(e) => update(e)}
               className={"file-input file-input-bordered file-input-primary w-full max-w-xs mt-5 " + classes} />
    )
}

export default InputFileUpload

import ProfileNavigation from "../ProfileNavigation";
import React, {useEffect, useState} from "react";
import '../../../css/index.css'
import primaryDots from "../../../images/StyleImprovement/primaryDots.png";
import regularDots from "../../../images/StyleImprovement/dotsRegular.png";
import randomLines from "../../../images/StyleImprovement/randomLines.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import vision1 from '../../../images/visionboard1.jpg'
import ModalDialog from "../../utils/ModalDialog";
import ToDos from "./ToDos";
import {useStoreContext} from "../../hooks/useStoreContext";
import {ConfigureJourneyStepModal} from "../../contents/modalContents/ConfigureJourneyStepModal";

export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
};

function ProfileDaily({editable=false}){
    const {store, dispatch} = useStoreContext();
    const [date, setDate] = useState(Date());
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [descVal, setDescVal] = useState("");
    const [background, setBackground] = useState();

    const handleDateChange = (e) => {
        setDate(e.target.value)
    }

    useEffect(() => {
        setDate(getCurrentDate());
    }, []);

    useEffect(() => {
        setLoading(store.loading)
        if(store.user.desc){
            setDescVal(store.user.desc.description)
        }
        if(store.images.background){
            setBackground(store.images.background.filter(a => a.desc_sub_type === "description")[0].images[0])
        }
    }, [store.user, store.images])

    return (
        <div className="hero min-h-screen bg-base-200 relative snap-start">
            {/*<img className="absolute z-0 min-h-screen opacity-50 " src={background} />*/}
            <img className="blur-3xl max-h-screen opacity-40 select-none" src={primaryDots} />
            <img className="max-h-screen opacity-50 blur-2xl absolute rotate-12 bottom-20 left-20 select-none" width={400} src={randomLines} />
            <div className="hero-overlay">
                {store.journey.length === 0 && !store.loading && <div className="flex sm:justify-end mx-auto container mt-20 gap-3">
                    <b className="mt-3 mr-16 text-lg animate-bounce">Füge neue Ziele hinzu → </b>
                    <button className="btn absolute btn-success top-20 sm:w-auto w-1/2 sm:mx-0 mx-auto "
                        onClick={() => document.getElementById(`addJourneyStep${1}`).showModal()}>
                        <FontAwesomeIcon icon={"add"} className="text-lg"/>
                    </button>
                    <ConfigureJourneyStepModal currentOrder={1} isNew={true}/>
                </div>}
            </div>
            <div className="hero-content w-full relative z-0 backdrop-blur-xl rounded-2xl p-5">
                <div className="w-full">
                    <label className="text-3xl mb-5">Deine Todos für den</label>
                    <input type="date" id="start" className="ml-5 sm:mt-0 mt-4 mb-6 input-lg text-xl bg-base-100 border-primary border rounded-t-lg" name="todoDate" value={date} min={getCurrentDate()}
                           onChange={handleDateChange} />
                    <ToDos date={date} />
                </div>
            </div>
            {/*<div className="flex justify-center">*/}
            {/*    <button className="btn btn-success absolute bottom-20 w-96" onClick={()=>document.getElementById('configureBackground').showModal()}>*/}
            {/*        <FontAwesomeIcon icon={"gear"} className=""/>Hintergrund konfigurieren</button>*/}
            {/*</div>*/}
            <ModalDialog title={"Hintergrund konfigurieren"} id={"configureBackground"} footer={<button className="btn btn-primary">Schließen</button>}>
                <div className="mt-5">
                    <h1 className="text-xl font-bold">Eigenes Hintergrund Bild hochladen: </h1>
                    <input type="file" className="file-input file-input-primary w-full max-w-xs mt-6" />
                    <hr className="mt-8"/>
                    <h1 className="text-xl font-bold mt-8">Muster auswählen: </h1>
                </div>
            </ModalDialog>
        </div>
    )
}

export default ProfileDaily;

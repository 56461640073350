import '../../css/App.css'
import React, { useState, useEffect } from "react";
import {createPortal} from "react-dom";

function ModalDialog({id, title, children, footer}) {
    return createPortal(
        <dialog id={id} className="modal text-center">
            <div className="modal-box max-w-5xl">
                {title && <h3 className="font-bold text-lg">{title}</h3>}
                {children && children}

                {footer && <div className="modal-action">
                    <form method="dialog">
                        {footer}
                    </form>
                </div>}
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>,
        document.querySelector("#modal-dialogs")
    );
}
export default ModalDialog;

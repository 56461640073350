import '../../css/App.css';
import {Route, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import $ from 'jquery';
import {Typewriter} from "../utils/Typewriter";
import RegisterAvatar from '../../images/RegisterAvatar.jpg'

function RegisterCardBody({title, chats, body, index, children}) {
    const [chatIndex, setChatIndex] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        setChatIndex(index)
        if(chats && index === chats.length+1){
            navigate("/register/2")
        }
    }, [index])

    return (
        <div className="card-body items-center text-center animate-fade-left animate-once animate-duration-1000 animate-ease-out animate-normal animate-fill-both ">
            <h2 className="card-title underline-offset-4 animate-jump-in text-3xl underline decoration-primary decoration-double">{title}</h2>
            <div className="divider"></div>
            {chats && chats.map((chat, i) => (
                <div className={"chat chat-start ml-10 w-1/2 " + (i !== 0 && "mt-6")} key={i}>
                    {(!index || i < chatIndex) &&
                        <>
                            <div className="chat-image avatar">
                                <div className="w-10 rounded-full">
                                    <img alt="Avatar" src={RegisterAvatar} />
                                </div>
                            </div>
                            <div className="chat-bubble chat-bubble-primary"><Typewriter text={chat} delay={20}/></div>
                        </>
                    }
                </div>
            ))}
            {body && body}

            <div className="divider"></div>
            <div className="card-actions justify-end">
                {children}
            </div>
        </div>
    );
}

export default RegisterCardBody;

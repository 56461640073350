import ModalDialog from "../../utils/ModalDialog";
import React, {useEffect, useRef, useState} from "react";
import InputGroup from "../../utils/InputGroup";
import axios from "axios";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";
import {useNavigate} from "react-router-dom";
import {createJourneyStep, deleteJourneyStep, editJourneyStep} from "../../../api/journey";

export function ConfigureJourneyStepModal({ currentOrder=0, item, isNew=false }){
    const {store, dispatch} = useStoreContext();
    const [goal, setGoal] = useState("");
    const [strategy, setStrategy] = useState("");
    const [value, setValue] = useState("");
    const [background, setBackground] = useState(null)
    const [isImg, setIsImg] = useState(false);
    const [backgroundPreview, setBackgroundPreview] = useState();
    const videoRef = useRef();
    const [order, setOrder] = useState("End");
    const navigate = useNavigate();

    useEffect(() => {
        if(item){
            setGoal(item.goal)
            setStrategy(item.strategy)
            setValue(item.value)
            setBackground(item.background)
            setBackgroundPreview(item.background)
            setIsImg(item.isImg)
        }
    }, [item])

    const changed = item && (goal === item.goal && strategy === item.strategy && value === item.value && background === item.background);

    useEffect(() => {
        videoRef.current?.load();
    }, [backgroundPreview])

    const goalChange = (e) =>  {
        setGoal(e.target.value)
    }
    const strategyChange = (e) => {
        setStrategy(e.target.value)
    }
    const valueChange = (e) => {
        setValue(e.target.value)
    }
    const backgroundChange = (e) => {
        const file = (e.target.files[0])
        if(file.type.startsWith("image/")) {
            setIsImg(true)
        }else if(file.type.startsWith("video/")){
            setIsImg(false)
        }
        setBackgroundPreview(URL.createObjectURL(file));
        setBackground(file)
    }

    const saveStepChanges = () => {
        if(strategy !== "" && goal !== "" && value !== "" && background){
            const formData = new FormData;
            if(backgroundPreview !== background){
                if(background.type.startsWith("image/")){
                    formData.append('image', background)
                }else if(background.type.startsWith("video/")){
                    formData.append('video', background)
                }else {
                    // Todo Alert
                    alert("Unsupported File Type")
                }
            }
            formData.append("goal", goal);
            formData.append("strategy", strategy);
            formData.append('value', value)
            // only needed for new
            formData.append('person_id', store.user.id);
            if(order === "Before"){
                formData.append('order_nr', currentOrder.toString())
            }else if(order === "End") {
                formData.append('order_nr', Math.max(...store.journey.map(o => o.order_nr))+1)
            }else {
                formData.append('order_nr', "1")
            }
            if(!isNew) formData.append("_method", "PUT");  // laravel does not work without that workaround

            if(isNew){
                const config = {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "multipart/form-data",
                        "Authorisation": "Bearer Token",
                    }
                }
                const addJourney = async () => {
                    await createJourneyStep(store.token, formData)
                        .then(res => {
                            dispatch({type: STORE_ACTIONS.SET, payload: {key: "journey", value: res}})
                            document.getElementById(`addJourneyStep${currentOrder}`).close();
                            setBackground(null)
                            setGoal("")
                            setStrategy("")
                            setBackgroundPreview(null)
                            setIsImg(false)
                            setOrder("End")
                        })
                }
                addJourney().finally();
            }else {
                const performEdit = async () => {
                    await editJourneyStep(store.token, item.id, formData)
                        .then(res => {
                            dispatch({type: STORE_ACTIONS.UPDATE_JOURNEY_STEP, payload: {step: res}})
                            document.getElementById(`addJourneyStep${currentOrder}`).close();
                        })
                }
                performEdit().finally();
            }
        }else {
            alert("Bitte fülle alle Daten aus!")
        }
    }

    const deleteItem = () => {
        const performDeletion = async () => {
            await deleteJourneyStep(store.token, item.id)
                .then(res => {
                    dispatch({type: STORE_ACTIONS.DELETE_JOURNEY_STEP, payload: {itemId: item.id}})
                    document.getElementById(`configureJourneyStep${item.id}`).close();
                })
        }
        performDeletion().finally();
    }

    const orderChange = (e) => {
        setOrder(e.target.value)
    }

    return (
        <ModalDialog title={"Step konfigurieren"} id={`${item ? `configureJourneyStep${item.id}` : `addJourneyStep${currentOrder}`}`}
                     footer={<><button className="btn">Schließen</button></>}>
            <div className="mt-5">
                <h1 className="text-xl font-bold">Hintergrundbild: </h1>
                <div className="flex justify-center items-center">
                    {/* TODO does not work, when data comes from DB*/}
                    {isImg ?
                        <img alt={backgroundPreview.toString()} className="w-1/2" src={backgroundPreview} />
                        :
                        <video ref={videoRef} autoPlay loop muted id={`StepVideo${item && item.id}`} className={`w-1/2 ${!backgroundPreview && "hidden"}`}>
                            <source src={backgroundPreview ? backgroundPreview : ""} type={`video/mp4`}/>
                        </video>
                    }
                </div>
                <input type="file" className="file-input file-input-primary w-full max-w-xs mt-6"
                       onChange={(e) => backgroundChange(e)} />
                <h1 className="text-xl font-bold mt-8">Ziel: </h1>
                <InputGroup placeholder={"Mein Titel"} change={goalChange} value={goal}/>
                <h1 className="text-xl font-bold mt-8">Strategie: </h1>
                <textarea cols={100} className="textarea mt-4 w-full textarea-bordered focus:textarea-primary " rows={4} placeholder="Definiere hier dein Ziel und deine Meilensteine"
                          value={strategy} onChange={(e) => strategyChange(e)}></textarea>
                <h1 className="text-xl font-bold mt-8">Vermögenswert: </h1>
                <textarea cols={100} className="textarea mt-4 w-full textarea-bordered focus:textarea-primary " rows={4} placeholder="Was möchtest du gerne mit deinem Ziel erreichen"
                          value={value} onChange={(e) => valueChange(e)}></textarea>
                {isNew &&
                    <div className="w-1/3 mt-5 mx-auto">
                        <label className="label cursor-pointer">
                            <span className="label-text">Am Start einfügen</span>
                            <input type="radio" onChange={orderChange} value="Start"  className="radio radio-primary" checked={order === "Start"} />
                        </label>
                        <label className="label cursor-pointer">
                            <span className="label-text">Vor diesem Schritt einfügen</span>
                            <input type="radio" onChange={orderChange} value="Before" className="radio radio-primary" checked={order === "Before"} />
                        </label>
                        <label className="label cursor-pointer">
                            <span className="label-text">Am Ende einfügen</span>
                            <input type="radio" onChange={orderChange} value="End"  className="radio radio-primary" checked={order === "End"} />
                        </label>
                    </div>
                }

                {!isNew && <button className={`btn btn-error mt-8`} onClick={deleteItem}>Löschen</button>}
                <button className={`btn btn-success mt-8 ml-4 ${changed ? "btn-disabled" : "btn-success"}`} onClick={saveStepChanges}>Speichern</button>
            </div>
        </ModalDialog>
    )
}

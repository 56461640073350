import '../../css/App.css'
import React, { useState, useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function InputGroup({title, placeholder, footer, change, value, type="text"}) {
    return (
        <div className="form-control w-full">
            <label className="label">
                <span className="label-text">{title}</span>
            </label>
            <input onChange={(e) => change(e)} type={type} value={value} placeholder={placeholder}
                   className="input input-bordered focus:input-primary w-full  " />
            <label className="label">
                {footer && footer}
            </label>
        </div>

    );
}
export default InputGroup;

import '../../css/App.css';
import '../../css/index.css';
import React, {useState, useEffect} from "react";
import $ from 'jquery';
import axios from 'axios';
import {Link} from "react-router-dom";
import RegisterCardBody from "./RegisterCardBody";
import InputGroup from "../utils/InputGroup";
import {PasswordInputGroup} from "../utils/PasswordInputGroup";
import {userRegister} from "../../api/person";

function RegisterPersonalData() {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordwdh, setPasswordwdh] = useState("");

    const [errormsg, setErrormsg] = useState("");
    const [errorState, setErrorState] = useState(0)

    function Register(){
        const checkLowerCase = new RegExp('[a-z]');
        const checkUpperCase = new RegExp('[A-Z]');
        const checkNumbers = new RegExp('[0-9]');
        if (firstname !== "" && lastname !== "" && username !== "" && email !== "" && password !== "" && passwordwdh !== "" && password === passwordwdh){
            if (checkLowerCase.exec(password) && checkUpperCase.exec(password) && checkNumbers.exec(password) && password.length >= 7){
                const performRegister = async ()=> {
                    const data = {firstname, lastname, username, email, password, password_confirmation: passwordwdh}
                    await userRegister(data)
                        .then(res => {
                            if(res.token){
                                $(".successAlert").removeClass("d-none");
                                $(".errorAlert").addClass("d-none");
                                $(".registerButton").addClass("d-none");
                                window.location.href="/login";
                            }else{
                                setErrormsg(res.data)
                                setErrorState(1)
                            }
                        })
                }
                performRegister().finally();

            }else {
                setErrormsg("Du braucht mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und eine Zahl in deinem Passwort. Außerdem muss das Passwort mindestens 7 Zeichen haben.")
                setErrorState(1)
            }
        }else {
            setErrormsg("Du hast nicht alles ausgefüllt oder die Passwörter stimmen nicht überein!")
            setErrorState(1)
        }
    }

    const body = (
        <div className={"grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10"}>
            <div>
                <InputGroup title={"Vorname"} placeholder={"Max"} value={firstname} change={(e) => setFirstname(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && firstname === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>
            <div>
                <InputGroup title={"Nachname"} placeholder={"Mustermann"} value={lastname} change={(e) => setLastname(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && lastname === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>
            <div>
                <InputGroup title={"E-Mail"} placeholder={"max@mustermann.de"} value={email} change={(e) => setEmail(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && email === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>
            <div>
                <InputGroup title={"Username"} placeholder={"muster123"} value={username} change={(e) => setUsername(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && username === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>
            <div>
                <PasswordInputGroup title={"Passwort"} placeholder={""} type={"password"} value={password} change={(e) => setPassword(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && password === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>
            <div>
                <PasswordInputGroup title={"Passwort wdh."} placeholder={""} type={"password"} value={passwordwdh} change={(e) => setPasswordwdh(e.target.value)}
                            footer={<span className={"label-text-alt text-error " + ((errorState === 1 && passwordwdh === "") ? "" : "hidden")}>
                                Bitte fülle dieses Feld aus!</span>}/>
            </div>


            <div className={"alert alert-error errorAlert col-span-3 " + (errorState === 0 && "hidden")} role="alert">
                {errormsg}
            </div>

        </div>
    )

    return (
        <div className="RegisterPersonalData">
            <RegisterCardBody title="Meine Persönlichen Daten" body={body}>
            <Link to={"/register/5"}><button className="btn btn-danger back" style={{marginRight: "2%"}}>Zurück</button></Link>
                <button className="btn btn-primary registerButton" type="submit" onClick={Register}>Registrieren</button>
            </RegisterCardBody>
    </div>
    );
}

export default RegisterPersonalData;
